import * as React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { PostTitle, PostBody } from '../components/styled'
import profilePic from '../images/profile.jpg'

const ProfileImage = styled.aside`
  margin-top: 2rem;

  img {
    border-radius: 50%;
    shape-outside: circle();
  }

  @media (min-width: 680px) {
    margin-top: 3rem;

    img {
      margin-right: 2rem;
      float: left;
    }
  }
`

const P = styled.p`
  @media (min-width: 680px) {
    text-align: justify;
  }
`

const About = (): JSX.Element => (
  <Layout>
    <PostTitle>About me</PostTitle>
    <PostBody>
      <ProfileImage>
        <img src={profilePic} alt="Profile picture of JP Erasmus" />
      </ProfileImage>
      <P>
        I&apos;m a frontend-focused full-stack developer with broad experience
        building on the Web. Currently, you&apos;ll find me working with a fine
        group of people on{' '}
        <a
          href="https://flamelink.io"
          target="_blank"
          rel="noreferrer noopener"
        >
          Flamelink - A Firebase CMS
        </a>
        .
      </P>
      <P>
        Before Flamelink, I was the frontend lead across the different feature
        teams for <a href="https://www.goresponsive.com/mychep">myCHEP</a>{' '}
        ensuring all the different squads were able to effectively collaborate
        and deliver quality features on such a large project.
      </P>
      <P>
        I love all things &quot;web&quot; with a keen interest in progressive
        web apps and the functional programming side of JavaScript.
      </P>
      <P>
        My <a href="https://twitter.com/jpunk11">Twitter DM&apos;s</a> are open
        if you want to chat.
      </P>
    </PostBody>
  </Layout>
)

export default About
